import React from "react"
import { Link } from "gatsby"
import logo from "../images/logo.svg"
import hamburger from "../images/hamburger-icon.svg"
import donate from "../images/donate-icon.svg"
import "../assets/style.scss"

export default function Header(props) {
  const { currentPage } = props

  const openNav = () => {
    document.getElementById("mySidenav").style.width = "250px"
  }

  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0px"
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-glass fixed-top">
      <div className="container">
        <button
          className="navbar-toggler border-0"
          onClick={() => openNav()}
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <img src={hamburger} width="40" alt="Menu" />
        </button>
        <Link to="/" className="navbar-brand mr-0">
          <img
            src={logo}
            width="100"
            alt="Swapnanagar"
            className="pb-2 pt-0 mt-0"
          />
        </Link>
        <Link
          to="/donate"
          className="navbar-toggler border-0 "
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <img src={donate} width="40" alt="Donate" />
        </Link>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto">
            <li
              className={
                currentPage === "home"
                  ? "nav-item mx-3 active nav-underline"
                  : "nav-item mx-3"
              }
            >
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            <li
              className={
                currentPage === "journey"
                  ? "nav-item mx-3 active nav-underline"
                  : "nav-item mx-3"
              }
            >
              <Link to="/journey" className="nav-link">
                Our Journey
              </Link>
            </li>
            <li
              className={
                currentPage === "projects"
                  ? "nav-item mx-3 active nav-underline"
                  : "nav-item mx-3"
              }
            >
              <Link to="/projects" className="nav-link">
                Projects
              </Link>
            </li>
            <li
              className={
                currentPage === "activities"
                  ? "nav-item mx-3 active nav-underline"
                  : "nav-item mx-3"
              }
            >
              <Link to="/activities" className="nav-link">
                Activities
              </Link>
            </li>
            <li
              className={
                currentPage === "join-us"
                  ? "nav-item mx-3 active nav-underline"
                  : "nav-item mx-3"
              }
            >
              <Link to="/join-us" className="nav-link">
                Join Us
              </Link>
            </li>
          </ul>
          <form className="form-inline my-2 my-lg-0" style={{ width: "130px" }}>
            <Link to="/donate" className="btn btn-primary my-2 my-sm-0">
              Donate
            </Link>
          </form>
        </div>
      </div>
      <div id="mySidenav" className="sidenav">
        <button className="closebtn" onClick={() => closeNav()}>
          &times;
        </button>
        <Link
          to="/"
          className={
            currentPage === "home"
              ? "nav-link py-3 nav-active"
              : "nav-link py-3"
          }
        >
          Home
        </Link>
        <Link
          to="/journey"
          className={
            currentPage === "journey"
              ? "nav-link py-3 nav-active"
              : "nav-link py-3"
          }
        >
          Our Journey
        </Link>
        <Link
          to="/projects"
          className={
            currentPage === "projects"
              ? "nav-link py-3 nav-active"
              : "nav-link py-3"
          }
        >
          Projects
        </Link>
        <Link
          to="/activities"
          className={
            currentPage === "activities"
              ? "nav-link py-3 nav-active"
              : "nav-link py-3"
          }
        >
          Activities
        </Link>
        <Link
          to="/join-us"
          className={
            currentPage === "join-us"
              ? "nav-link py-3 nav-active"
              : "nav-link py-3"
          }
        >
          Join Us
        </Link>
        <Link to="/donate" className="btn btn-primary m-4 my-2 my-sm-0">
          Donate
        </Link>
        <h1>
          <i className="fab fa-facebook pr-3"></i>
          <i className="fab fa-twitter pr-3"></i>
          <i className="fab fa-youtube pr-3"></i>
        </h1>
      </div>
    </nav>
  )
}
