import React from "react"
import footerLeft from "../images/footer_left.svg"

export default function Footer() {
  return (
    <div className="container-fluid m-0 p-0 overflow-hidden ">
      <div className="d-flex custom-footer ">
        <div className="footer-element-1">
          <img src={footerLeft} alt="Footer blob" />
        </div>

        <div className="row mx-4 mt-5" style={{ flex: 1 }}>
          <div className="col-lg-4">
            <h3 className="mb-3">Connect with us</h3>
            <span>
              Swapnanagar Bidyaniketon,
              <br />
              Kanchannagar Patiya,
              <br />
              Chittagong, Bangladesh
            </span>
          </div>
          <div className="col-lg-4">
            <div className="mt-5">
              <span> +880-17307-03024 </span>
              <br />
              <span>hello@swapnanagar.org</span>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mt-5">
              <h1>
                <i className="fab fa-facebook pr-3"></i>
                <i className="fab fa-twitter pr-3"></i>
                <i className="fab fa-youtube pr-3"></i>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
